import React, {useContext} from "react";
import {NavLink, Link, useLocation} from "react-router-dom";
import PackageJson from "../../package.json";
import {Context} from "./Context";
import {calculationName, calculationNameNavLink} from "./Helper";

const Aside = () => {
    const location = useLocation();
    const [context] = useContext(Context);
    const calculationsNavLink = [
        "billboard_global_200_excl_us",
        "billboard_global_200",
        "billboard_top_100_artist",
        "billboard_top_200_albums",
        "billboard_hot_100",
        "SoundChartTop100To139",
        "SoundChartHipHopRap",
        "SoundChartTop200US",
        "scraper_weekly_top_albums/global",
        "scraper_weekly_top_albums/us",
        "scraper_daily_top_artists/global",
        "scraper_daily_top_artists/us",
        "scraper_daily_viral_tracks/global",
        "scraper_daily_viral_tracks/us",
        "scraper_daily_top_tracks/global",
        "scraper_daily_top_tracks/us",
        "itune/higher",
        "itune/lower",
        "spotify/playlist_tracks",
        "turnt/playlist",
        "soundchart_rankings_spotify",
        "soundchart_broadcast_tracks",
        "soundchart_spotify_listeners",
        "soundchart_youtube_listeners",
        "buy_&_sell",
    ];

    return (
        <aside className="admin-aside">
            <div className="admin-logobox">
                <Link to="dashboard" className="admin-brandbox" title="Dashboard">
                    <img src="/logo.png" alt="Xipetech-Logo" width="220" height="55.64" />
                </Link>
            </div>

            <div className="admin-navbox" id="navtoggle">
                {context && context.auth && context.auth.role_id === 1 && (
                    <>
                        <div className="accordion-item">
                            <NavLink to="dashboard" className="main-link" title="Dashboard">
                                <svg className="icon" role="img">
                                    <use href="#icon_dashboard" />
                                </svg>
                                Dashboard
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="assets" className="main-link" title="Assets">
                                <svg className="icon tscale-1p1" role="img">
                                    <use href="#icon_suitcase" />
                                </svg>
                                Assets
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink
                                data-bs-toggle="dropdown"
                                to="calculations"
                                className="main-link"
                                title="Calculations"
                            >
                                <svg className="icon tscale-1p1" role="img">
                                    <use href="#icon_percentage" />
                                </svg>
                                Calculations
                            </NavLink>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto calculations-menu">
                                {calculationsNavLink &&
                                    calculationsNavLink.map((item, i) => (
                                        <React.Fragment key={i}>
                                            <li>
                                                <NavLink
                                                    title={calculationName(item)}
                                                    to={"Calculations/" + item}
                                                    className="dropdown-item rounded-20 text-blue py-2"
                                                >
                                                    {calculationNameNavLink(item)}{" "}
                                                </NavLink>
                                            </li>
                                        </React.Fragment>
                                    ))}
                            </ul>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="percentImpactScale" className="main-link" title="Percentage Impact Scale">
                                <svg className="icon tscale-1p1" role="img">
                                    <use href="#icon_percentage" />
                                </svg>
                                Percentage Impact Scale
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="specialChars" className="main-link" title="Special Characters">
                                <svg className="icon tscale-1p1" role="img">
                                    <use href="#icon_filter" />
                                </svg>
                                Special Characters
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="users" title="Users" className="main-link">
                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                    <use href="#icon_users" />
                                </svg>
                                Users
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink
                                to="albums"
                                title="Albums"
                                className={`main-link ${location.pathname.match("/albums") ? "active" : ""}`}
                            >
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_posts" />
                                </svg>
                                Albums
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink
                                to="posts"
                                title="Posts"
                                className={`main-link ${location.pathname.match("/posts") ? "active" : ""}`}
                            >
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_posts" />
                                </svg>
                                Posts
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="comments" title="Comments" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_chat" />
                                </svg>
                                Comments
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="transactions" title="Transactions" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_transaction" />
                                </svg>
                                Transactions
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="activitylist" title="Activity Logs" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_layers" />
                                </svg>
                                Activity Logs
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="settings" title="Settings" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_setting" />
                                </svg>
                                Settings
                            </NavLink>
                        </div>
                        <div className="accordion-item">
                            <NavLink to="contacts" title="Contacts" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_setting" />
                                </svg>
                                Contacts
                            </NavLink>
                        </div>
                        <div className="accordion-item mt-4">
                            <NavLink to="/" title="App View" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_dashboard" />
                                </svg>
                                App View
                            </NavLink>
                        </div>{" "}
                    </>
                )}
            </div>
            <div className="admin-copyrightsbox">
                &copy; 2022 - Xipetech - All Rights Reserved | v{PackageJson.version}
            </div>
        </aside>
    );
};
export default Aside;
